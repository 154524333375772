import axios from 'axios';
const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/presets`;
export const savePresetTask = async (preset) => {
    try {
        await axios.post(API_BASE_URL, preset);
    } catch (error) {
        console.error('Failed to save preset:', error);
        throw error;
    }
};
export const updatePreset = async (id, preset) => {
    try {
        await axios.patch(`${API_BASE_URL}/${id}`, preset);
    } catch (error) {
        console.error('Failed to update preset:', error);
        throw error;
    }
};
export const fetchPresets = async () => {
    try {
        const response = await axios.get(API_BASE_URL);
        return response.data;
    } catch (error) {
        console.error('Failed to load presets:', error);
        throw error;
    }
};
export const fetchPresetById = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching preset:', error);
        throw error;
    }
};
export const deletePreset = async (id) => {
    try {
        await axios.delete(`${API_BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting Preset:', error);
        throw error;
    }
};

