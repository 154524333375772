import axios from 'axios';
const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/tasks`;
export const fetchTasksByDate = async (date) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/date/${date}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};
export const fetchTasks = async (date) => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};
export const fetchTaskById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};
export const createTask = async (task) => {
  try {
    const response = await axios.post(API_BASE_URL, task);
    return response.data;
  } catch (error) {
    console.error('Error creating task:', error);
    throw error;
  }
};
export const updateTaskOrder = async (tasks) => {
  try {
    await axios.post(`${API_BASE_URL}/reorder`, { tasks });
  } catch (error) {
    console.error('Failed to update task order:', error);
  }
};
export const completeTask = async (id) => {
  try {
      await axios.put(`${API_BASE_URL}/${id}/complete`);
  } catch (error) {
    console.error('Failed to update task:', error);
  }
}
export const updateTask = async (id, updatedTask) => {
  try {
    const response = await axios.patch(`${API_BASE_URL}/${id}`, updatedTask);
    return response.data;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};

export const deleteTask = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/${id}`);
  } catch (error) {
    console.error('Error deleting task:', error);
    throw error;
  }
};

