import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { updatePreset, fetchPresetById } from '../../utils/presetApi';
import { useParams, useNavigate } from 'react-router-dom';

const PresetForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [presetData, setPresetData] = useState({
        title: '',
        description: ''
    });
    useEffect(() => {
        const loadPreset = async () => {
            try {
                const preset = await fetchPresetById(id);
                setPresetData(preset);
            } catch (error) {
                console.error('Failed to fetch preset:', error);
            }
        };

        loadPreset();
    }, [id]);

    const handleChange = (e) => {
        setPresetData({
            ...presetData, [e.target.name]: e.target.value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updatePreset(id, presetData);
            navigate(-1);
        } catch (error) {
            console.error('Failed to update preset:', error);
        }
    };

    const handleCancel = () => {
        navigate(-1); // This navigates back to the previous page
    };




    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: '20px auto',
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: 'white',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                boxSizing: 'border-box',
                '@media (max-width: 600px)': {
                    padding: '10px',
                },
            }}
        >
            <form
                onSubmit={handleSubmit}
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >

                <TextField
                    label="Title"
                    name="title"
                    value={presetData.title}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Description"
                    name="description"
                    value={presetData.description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button type="submit" variant="contained">
                        Save Preset
                    </Button>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default PresetForm;
