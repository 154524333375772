import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemText, Container } from '@mui/material';

function PublicDashboard() {
  return (
    <Container maxWidth="sm">
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          Welcome to Priority Pals
        </Typography>
        <Typography variant="body1">
          Manage your tasks efficiently and effectively.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>
          Features:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Organize tasks by priority" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Set due dates and receive reminders" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Track your progress with visual indicators" />
          </ListItem>
        </List>
      </Box>
      <Box textAlign="center" mt={4}>
        <Button variant="contained" color="primary" href="/login" sx={{ mr: 2 }}>
          Login
        </Button>
        <Button variant="outlined" color="primary" href="/register">
          Register
        </Button>
      </Box>
    </Container>
  );
}

export default PublicDashboard;
