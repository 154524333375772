import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/user/Login';
import Register from './components/user/Register';
import PrivateRoute from './PrivateRoute';
import PublicDashboard from './components/PublicDashboard';
import TaskForm from './components/task/TaskForm';
import EditTaskForm from './components/task/EditTaskForm';
import Presetlist from './components/preset/PresetList';
import PresetForm from './components/preset/PresetForm';
import EditPresetForm from './components/preset/EditPresetForm';
import BaseLayout from './components/BaseLayout';
import Profile from './components/user/Profile';
import TaskList from './components/task/TaskList';

function App() {
  return (
    <Router>
      <BaseLayout>
        <Routes>
          {/* Route for unauthenticated users */}
          <Route path="/" element={<PublicDashboard />} />

          {/* Route for authenticated users */}
          {/* Tasks */}
          <Route path="/tasks" element={<PrivateRoute><TaskList /></PrivateRoute>} />
          <Route path="/create-task" element={<PrivateRoute><TaskForm /></PrivateRoute>} />
          <Route path="/edit-task/:id" element={<PrivateRoute><EditTaskForm /></PrivateRoute>} />

          {/* Presets */}
          <Route path="/presets" element={<PrivateRoute><Presetlist /></PrivateRoute>} />
          <Route path="/create-preset" element={<PrivateRoute><PresetForm /></PrivateRoute>} />
          <Route path="/edit-preset/:id" element={<PrivateRoute><EditPresetForm /></PrivateRoute>} />
          {/* Auth routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile/:id" element={<PrivateRoute><Profile /></PrivateRoute>} />

        </Routes>
      </BaseLayout>
    </Router>
  );
}

export default App;
