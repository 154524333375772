import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemText } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';
import { deletePreset, fetchPresets } from '../../utils/presetApi';

function PresetListPage() {
    const [presets, setPresets] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        loadPresets();
    }, []);
    const loadPresets = async () => {
        try {
            const presets = await fetchPresets();
            setPresets(presets);
        } catch (error) {
            console.error('Failed to fetch presets:', error);
        }
    }
    const handleDeletePreset = async (id) => {
        try {
            await deletePreset(id);
            loadPresets();
        } catch (error) {
            console.error('Failed to delete task:', error);
        }
    };

    const handleEditPreset = (id) => {
        navigate(`/edit-preset/${id}`);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '600px', margin: '20px auto', textAlign: 'center' }}>
            <Button component={Link} to="/create-preset" variant="contained" sx={{ marginBottom: '20px' }}>
                Create New Preset
            </Button>
            <List>
                {presets.map((preset, index) => (
                    <ListItem key={preset._id} sx={{ display: 'flex', alignItems: 'center' }}>

                        <ListItemText
                            primary={<>{preset.title} </>}
                            secondary={
                                <>
                                    {preset.description && <span>{preset.description}<br /></span>}
                                </>
                            }
                        />
                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditPreset(`${preset._id}`)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePreset(preset._id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Box>

        // <Container>
        //     <Typography variant="h4" gutterBottom>Task Presets</Typography>
        //     <List>
        //         {presets.map((preset) => (
        //             <ListItem key={preset.id}>
        //                 <ListItemText primary={preset.name} secondary={preset.description} />
        //                 <ListItemSecondaryAction>
        //                     <IconButton edge="end" aria-label="edit" onClick={() => handleEditPreset(preset.id)}>
        //                         <Edit />
        //                     </IconButton>
        //                     <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePreset(preset.id)}>
        //                         <Delete />
        //                     </IconButton>
        //                 </ListItemSecondaryAction>
        //             </ListItem>
        //         ))}
        //     </List>
        //     <Button variant="contained" color="primary" onClick={() => navigate('/presets/new')}>
        //         Add New Preset
        //     </Button>
        // </Container>
    );
}

export default PresetListPage;
