import React, { useState, useEffect } from 'react';
import { Button, Box, TextField, MenuItem, FormControlLabel, FormControl, Checkbox, InputLabel, Select } from '@mui/material';
import { fetchTaskById, updateTask } from '../../utils/taskApi';
import { useParams, useNavigate } from 'react-router-dom';

const EditTaskForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [taskData, setTaskData] = useState({
        title: '',
        description: '',
        priority: 'Medium',
        dueDate: formatDateDisplay(new Date()),
        completed: false,
        recurring: false,
        recurrenceFrequency: 'daily'
    });

    useEffect(() => {
        const loadTask = async () => {
            try {
                const task = await fetchTaskById(id);
                task.dueDate = fromUTC(task.dueDate);
                setTaskData(task);
            } catch (error) {
                console.error('Failed to fetch task:', error);
            }
        };

        loadTask();
    }, [id]);

    const handleChange = (e) => {
        setTaskData({
            ...taskData,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    };
    function fromUTC(utcDate) {
        const date = new Date(utcDate);
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };

    function setDateToUTC() {
        const dateLocal = new Date(taskData.dueDate);
        taskData.dueDate = new Date(dateLocal.getTime() + dateLocal.getTimezoneOffset() * 60000);
        setTaskData(taskData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setDateToUTC();
            await updateTask(taskData._id, taskData);
            navigate(-1);
        } catch (error) {
            console.error('Failed to update task:', error);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
                margin: '20px auto',
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: 'white',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                boxSizing: 'border-box',
                '@media (max-width: 600px)': {
                    padding: '10px',
                },
            }}
        >
            <form
                onSubmit={handleSubmit}
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <TextField
                    label="Title"
                    name="title"
                    value={taskData.title}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Description"
                    name="description"
                    value={taskData.description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    select
                    label="Priority"
                    name="priority"
                    value={taskData.priority}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                >
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                </TextField>
                <TextField
                    label="Due Date"
                    name="dueDate"
                    type="datetime-local"
                    value={formatDateDisplay(taskData.dueDate) }
                    onChange={handleChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                />
                <FormControlLabel
                    control={<Checkbox name='recurring' checked={!!taskData.recurring} onChange={handleChange} />}
                    label="Recurring Task"
                />
                {taskData.recurring && (
                    <FormControl fullWidth>
                        <InputLabel id="recurrence-frequency-label">Recurrence Frequency</InputLabel>
                        <Select
                            name='recurrenceFrequency'
                            labelId="recurrence-frequency-label"
                            value={taskData.recurrenceFrequency}
                            onChange={handleChange}
                            label="Recurrence Frequency"
                        >
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                        </Select>
                    </FormControl>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button type="submit" variant="contained">
                        Update Task
                    </Button>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

function formatDateDisplay(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        h = '' + d.getHours(),
        m = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (h.length < 2)
        h = '0' + h;
    if (m.length < 2)
        m = '0' + m;
    return [year, month, day].join('-') + 'T' + [h, m].join(':');
}
export default EditTaskForm;
