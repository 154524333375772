import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import { AuthContext } from '../../context/Auth';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const { register } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await register(email, password);
    navigate('/tasks'); // Redirect to dashboard after registration
  };

  return (
    <Container maxWidth="xs">
      <Box textAlign="center" mb={4}>
        <Typography variant="h5">Register</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          required
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          required
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box mt={2}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Register
          </Button>
        </Box>
      </form>
      <Box textAlign="center" mt={2}>
        <Typography variant="body2">
          Already have an account? <a href="/login">Login</a>
        </Typography>
      </Box>
    </Container>
  );
}

export default Register;
