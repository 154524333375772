import React, { useState, useContext } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/Auth';
function MenuButton() {

    const { user } = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        navigate(path);
        handleMenuClose();
    };


    return (
        <div>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleNavigate('/')}>Home</MenuItem>
                {user && (<MenuItem onClick={() => handleNavigate('/tasks')}>Tasks</MenuItem>)}
                {user && (<MenuItem onClick={() => handleNavigate('/presets')}>Presets</MenuItem>)}
                {user && (<MenuItem onClick={() => handleNavigate(`/profile/${user._id}`)}>Profile</MenuItem>)}


                {/* 
        <MenuItem onClick={() => handleNavigate('/profile')}>Profile</MenuItem>
        <MenuItem onClick={() => handleNavigate('/settings')}>Settings</MenuItem> */}
            </Menu>
        </div>
    );
}

export default MenuButton;
