import axios from 'axios';
const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/`;

export const fetchProfileById = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}profile/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching profile:', error);
        throw error;
    }
};

export const updateUser = async (profileData) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}profile/`, profileData);
        return response.data;
    } catch (error) {
        console.error('Failed to update profile:', error);
        return false;
    }
};
export const sendVerificationCode = async (email) => {
    try {
        const response = await axios.post(`${API_BASE_URL}send-verification`, { email });
        return response.data;
    } catch (error) {
        console.error('Failed to send verification code:', error);
        return false;
    }
};
export const verifyEmail = async (verificationCode) => {
    try {
        const response = await axios.post(`${API_BASE_URL}verify-email`, { verificationCode });
        return response.data;
    } catch (error) {
        console.error('Failed to verify email:', error);
        return false;
    }
};