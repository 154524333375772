import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';
import { fetchProfileById, updateUser, sendVerificationCode, verifyEmail } from '../../utils/profileApi';
import { useParams } from 'react-router-dom';

const ProfilePage = () => {

    const { id } = useParams();
    const [profileData, setProfileData] = useState({
        name: '',
        email: '',
        isEmailVerified: false,
    });
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationSent, setVerificationSent] = useState(false);
    useEffect(() => {
        const loadProfile = async (id) => {
            try {
                const profile = await fetchProfileById(id);
                setProfileData(profile);
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        };

        loadProfile();
    }, [id]);
    const handleChange = (e) => {
        setProfileData({ ...profileData, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        // Call API to save updated profile data
        const success = await updateUser(profileData);
        if (success) {
            alert('Profile updated successfully!');
        } else {
            alert('Failed to update profile.');
        }
    };

    const handleSendVerification = async () => {
        // Call API to send verification code
        const success = await sendVerificationCode(profileData.email);
        if (success) {
            setVerificationSent(true);
            alert('Verification code sent!');
        } else {
            alert('Failed to send verification code.');
        }
    };

    const handleVerifyEmail = async () => {
        const success = await verifyEmail(verificationCode);
        if (success) {
            setProfileData({ ...profileData, isEmailVerified: true });
            alert('Email verified successfully!');
        } else {
            alert('Invalid verification code.');
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                Profile
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={profileData.name}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={profileData.email}
                        onChange={handleChange}
                        disabled
                    />
                </Grid>
                {profileData.isEmailVerified ? (
                    <Typography variant="body1" color="primary">
                        Email verified.
                    </Typography>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendVerification}
                                disabled={verificationSent}
                            >
                                {verificationSent ? 'Verification Sent' : 'Verify Email'}
                            </Button>
                        </Grid>
                        {verificationSent && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Verification Code"
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleVerifyEmail}
                                    >
                                        Verify
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </>
                )}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save Changes
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProfilePage;
