import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography } from '@mui/material';

const PomodoroTimer = () => {
  const WORK_TIME = 25 * 60; // 25 minutes in seconds
  const BREAK_TIME = 5 * 60; // 5 minutes in seconds
  const LONG_BREAK_TIME = 15 * 60; // 15 minutes in seconds

  const [isWork, setIsWork] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(WORK_TIME);
  const [cycleCount, setCycleCount] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {

    const switchMode = () => {
      if (isWork) {
        setCycleCount(prev => prev + 1);
        if (cycleCount >= 3) {
          setTimeLeft(LONG_BREAK_TIME);
          setCycleCount(0); // Reset cycle count after a long break
        } else {
          setTimeLeft(BREAK_TIME);
        }
      } else {
        setTimeLeft(WORK_TIME);
      }
      setIsWork(!isWork);
    };

    if (isRunning) {
      timerRef.current = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            switchMode();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [BREAK_TIME, LONG_BREAK_TIME, WORK_TIME, cycleCount, isRunning, isWork]);



  const handleStartStop = () => {
    setIsRunning(!isRunning);
  };

  const handleReset = () => {
    clearInterval(timerRef.current);
    setIsRunning(false);
    setTimeLeft(isWork ? WORK_TIME : BREAK_TIME);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h4">
        {isWork ? 'Work Time' : cycleCount >= 3 ? 'Long Break' : 'Break Time'}
      </Typography>
      <Typography variant="h2">
        {formatTime(timeLeft)}
      </Typography>
      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleStartStop}>
          {isRunning ? 'Pause' : 'Start'}
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleReset} style={{ marginLeft: '10px' }}>
          Reset
        </Button>
      </div>
    </div>
  );
};

export default PomodoroTimer;
